<template>
  <div>
    <div class="vh-100">
      <embed style="height: 100vh; width: 100%" src="https://www.youtube.com/embed/JH3ytuQnFwc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  computed: {},
};
</script>

<style>
body,
html {
  height: 100%;
}

#app.player-margin {
 margin-bottom: 0 !important   
}

#app .navbar {
    display: none !important;
}
</style>
